<template>
  <div class="">
    <div class="tj-box">
      <div class="item">
        <div class="ico-box color4"><div class="ico"><i class="i el-icon-money"></i></div></div>
        <div>
          <div class="amount">{{ tj.commission }}</div>
          <div class="tips">剩余佣金</div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color3"><div class="ico"><i class="i el-icon-s-data"></i></div></div>
        <div>
          <div class="amount">{{ tj.commission_settle }}</div>
          <div class="tips">累计结算佣金</div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color2"><div class="ico"><i class="i el-icon-postcard"></i></div></div>
        <div>
          <div class="amount">{{ tj.commission_withdraw }}</div>
          <div class="tips">已提现佣金</div>
        </div>
      </div>
      <div class="item">
        <div class="ico-box color1"><div class="ico"><i class="i el-icon-postcard"></i></div></div>
        <div>
          <div class="amount">{{ tj.commission_withdraw_ing }}</div>
          <div class="tips">提现中佣金</div>
        </div>
      </div>
    </div>
    <div class="main-box mg-tp-10">
      <el-form class="form-inline" :inline="true">
        <el-form-item label="">
          <el-date-picker
              size="small"
              v-model="dateRange"
              type="daterange"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
        </el-form-item>
      </el-form>
      <div class="char_title mg-tp-30">佣金统计</div>
      <div class="echart" id="mychart1" :style="myChartStyle"></div>
      <el-row>
        <el-col :span="12">
          <div class="char_title mg-tp-30">来源统计</div>
          <div class="echart" id="mychart10" :style="myChartStyle"></div>
        </el-col>
        <el-col :span="12">
          <div class="char_title mg-tp-30">消耗统计</div>
          <div class="echart" id="mychart11" :style="myChartStyle"></div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>

import * as echarts from "echarts";
import dayjs from "dayjs";
export default {
  components: {
  },
  data() {
    return {
      tj: {
        commission: 0,
        commission_settle: 0,
        commission_withdraw: 0,
        commission_withdraw_ing: 0,
      },
      dataInfo: false,
      dateRange: ['',''],
      myChartStyle: { float: "left", width: "100%", height: "300px" },
      myChart1: false,
      myChart10: false,
      myChart11: false,
      pickerOptions: {
        shortcuts: [{
          text: '当日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当周',
          onClick(picker) {
            var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
            var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
            if(new Date().getDay()==0) { //周日前推6天
              start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
              end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
            }
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当月',
          onClick(picker) {
            const start = new Date();
            start.setDate(1)
            const end = new Date(start.getFullYear(),start.getMonth()+1,0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当季度',
          onClick(picker) {
            const today = new Date();
            const year = today.getFullYear();
            const quarter = Math.floor((today.getMonth() + 3) / 3);
            const start = new Date(year, (quarter - 1) * 3, 1);
            const end = new Date(year, quarter * 3, 0);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  created() {
    var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
    var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
    if(new Date().getDay()==0) { //周日前推6天
      start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
      end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
    }
    this.dateRange[0] = start
    this.dateRange[1] = end
    this.getStatic()
    this.handleSearch()
  },
  mounted() {
  },
  computed: {},
  methods: {
    getStatic() {
      this.$api.member.memberCommissionHome({}, res=> {
        this.tj = res.data
      })
    },
    handleSearch() {
      var params = {
        start_time: this.dateRange[0],
        end_time: this.dateRange[1],
      }
      this.showLoading()
      this.$api.member.memberCommissionTjTime(params, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.dataInfo = res.data
          this.initEcharts1();
          this.initEcharts10();
          this.initEcharts11();
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getOption(data1,data2,title1,title2) {
      var options = {
        tooltip: {},
        legend: {
          data: [title1, title2]
        },
        xAxis: {
          data: this.dataInfo.x
        },
        yAxis: {},
        series: [
          {
            name: title1,
            type: 'line',
            data: data1
          },
          {
            name: title2,
            type: 'line',
            data: data2
          }
        ]
      };
      return options
    },
    initEcharts1() {
      var options = this.getOption(this.dataInfo.data11,this.dataInfo.data12,'发放佣金','消耗佣金')
      this.myChart1 = echarts.init(document.getElementById("mychart1"));
      this.myChart1.setOption(options);
      window.addEventListener("resize", () => {
        this.myChart1.resize();
      });
    },
    initEcharts10() {
      const option = {
        legend: {
          // orient: 'vertical',
          left: 'center'
        },
        tooltip: {},
        series: [{
          name: '佣金来源',
          type: 'pie',
          radius: '50%',
          data: this.dataInfo.pie1
        }]
      };
      this.myChart10 = echarts.init(document.getElementById("mychart10"));
      this.myChart10.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart10.resize();
      });
    },
    initEcharts11() {
      const option = {
        legend: {
          // orient: 'vertical',
          left: 'center'
        },
        tooltip: {},
        series: [{
          name: '佣金消耗',
          type: 'pie',
          radius: '50%',
          data: this.dataInfo.pie2
        }]
      };
      this.myChart11 = echarts.init(document.getElementById("mychart11"));
      this.myChart11.setOption(option);
      window.addEventListener("resize", () => {
        this.myChart11.resize();
      });
    }
  }
};
</script>
<style scoped>
</style>
